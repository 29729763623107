<template>
  <b-btn v-scroll-top variant class="scroll-button" @click="scrollTop"
    ><i class="icon-up-9"></i
  ></b-btn>
</template>

<script>
export default {
  name: "ScrollButton",
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>
